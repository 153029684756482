import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { DeviceContext } from '~/contexts/DeviceContext';

import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import useImageDimensions from '~/paper/hooks/imageDimensions';
// types
import type {
  WCmsPageImageView,
  WLengthView,
  WPublicWeddingView,
} from '@zola/svc-web-api-ts-client';
// utils
import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import _debounce from 'lodash/debounce';
import { getFontSizeOverride } from '~/components/publicWebsiteV2/pageLayouts/SinglePageLayout/helpers/responsiveFontSizeHelpers';
import { renderAbsoluteAssets } from '~/components/publicWebsiteV2/util/renderHelpers';
import { appendWidthParam } from '~/components/publicWebsiteV2/util/getRelativeImgSize';
import dynamic from 'next/dynamic';

// styles
import mapFontValuesToComponent from '~/components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { FoilAnimationWrapper } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import { useAnimationContext } from '~/components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import type { PartialComponentFontView } from '../../../../../util/mappers/types';

// TODO: move to more common directory
import {
  getHeroTitleOverrideStyles,
  HeroImage,
  MobileScrollDownCaret,
  TitleContainer,
  Title,
  getHeroContainerStyles,
  TintedOverlay,
} from './Hero.styles';

const OverlayAnimation = dynamic(
  () => import('~/components/publicWebsiteV2/common/OverlayAnimation/OverlayAnimation'),
  { ssr: false }
);

const Hero = ({
  title,
  className,
  onDownCaretClick,
  images = [],
  placeholderImg,
  isSamplePage,
  isDesktop,
  editWebsiteWedding,
  forceShowOverlay,
}: {
  title?: string;
  className?: string;
  onDownCaretClick?: () => void;
  images?: WCmsPageImageView[];
  placeholderImg?: string;
  isSamplePage?: boolean;
  isDesktop?: boolean;
  // if component is used outside of website, we need to pass wedding in directly
  editWebsiteWedding?: WPublicWeddingView;
  forceShowOverlay?: boolean;
}): JSX.Element | null => {
  const {
    state: {
      components: { globalHeaderFontValues },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { MEDIA_QUERY } = useTheme();
  const { hideHeroTitleText } = useAnimationContext();

  const [heroImageSizedUrl, setHeroImageSizedUrl] = useState<string>();

  const desktopHeroImageUrl = (images || []).filter(
    (i) =>
      i?.type === (('HERO' as unknown) as WCmsPageImageView.TypeEnum) &&
      i?.size === (('RECTANGLE' as unknown) as WCmsPageImageView.SizeEnum)
  )[0]?.image_url;
  const mobileHeroImageUrl = (images || []).filter(
    (i) =>
      i?.type === (('HERO' as unknown) as WCmsPageImageView.TypeEnum) &&
      i?.size === (('SQUARE' as unknown) as WCmsPageImageView.SizeEnum)
  )[0]?.image_url;

  const getInitialImage = () => {
    let initialHeroImageUrl: string | undefined = desktopHeroImageUrl;
    if (isDesktop) {
      initialHeroImageUrl = desktopHeroImageUrl || placeholderImg;
    } else {
      initialHeroImageUrl = mobileHeroImageUrl || placeholderImg;
    }
    if (isSamplePage) {
      initialHeroImageUrl = wedding?.public_theme_v2?.sample_site_hero_image_url;
    }
    return initialHeroImageUrl;
  };

  const [heroImageUrl, setHeroImageUrl] = useState<string | undefined>(getInitialImage());

  const { absolute_assets: heroAbsoluteAssets, fonts } =
    wedding?.public_theme_v2?.components?.HERO_HOME || {};
  const heroHeaderFontStyles = fonts?.HEADER?.[1];
  const heroTitleStyles: PartialComponentFontView = {
    ...heroHeaderFontStyles,
    ...getHeroTitleOverrideStyles(Boolean(heroImageUrl)),
  };
  const StyledTitle = mapFontValuesToComponent({
    partial: heroTitleStyles,
    fallback: globalHeaderFontValues,
    excludeFontSize: true,
    mediaQuery: MEDIA_QUERY,
    ComponentType: Title,
  });

  const welcomeMessageFontSizeOverride = getFontSizeOverride(
    {
      desktop: 6,
      tablet: 8,
      mobile: 8,
      mobileXs: 7,
    },
    inPreview
  );
  const heroWrapperRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useContainerDimensions(heroWrapperRef);
  const { width: containerWidth } = containerDimensions;
  const imageDimensions = useImageDimensions(heroImageUrl ? `${heroImageUrl}?w=10` : undefined);
  const { width: imageWidth } = imageDimensions;

  useEffect(() => {
    // FIXME: There is a bug in preview where HomeContainer gets unmounted many times,
    // causing the hero image to reset and flash
    if (
      document &&
      containerDimensions.width > 0 &&
      (imageDimensions?.width || 0) > 0 &&
      !inPreview &&
      heroImageUrl
    ) {
      const img = document.createElement('img');
      const imageSizedUrl = appendWidthParam({
        src: heroImageUrl,
        style: {
          responsive_size: {
            desktop: {
              width: { unit: ('PERCENTAGE' as unknown) as WLengthView.UnitEnum, value: 100 },
            },
          },
        },
        width: containerWidth,
        device,
      });
      img.src = imageSizedUrl;

      img.onload = (): void => {
        setHeroImageSizedUrl(imageSizedUrl);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, imageWidth]);

  useEffect(() => {
    const handleResize = () => {
      if (mobileHeroImageUrl) {
        if (isMobile() || (inPreview && inPreview === 'MOBILE')) {
          // set hero image to mobile
          setHeroImageUrl(mobileHeroImageUrl);
        } else {
          setHeroImageUrl(desktopHeroImageUrl);
        }
      }
    };
    const debouncedHandleResize = _debounce(handleResize, 100);
    if (typeof window !== 'undefined') {
      handleResize();
      window.addEventListener('resize', debouncedHandleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', debouncedHandleResize);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!title && !heroImageUrl) return null;

  return (
    <div
      className={className}
      css={getHeroContainerStyles({
        hasImage: Boolean(heroImageUrl),
        mediaQuery: MEDIA_QUERY,
        overrideMobileHeight: containerWidth,
      })}
      ref={heroWrapperRef}
    >
      <HeroImage
        src={heroImageSizedUrl || heroImageUrl}
        overrideMobileHeight={inPreview && inPreview === 'MOBILE' ? containerWidth : undefined}
        inPreview={inPreview}
      />

      <TitleContainer>
        {!hideHeroTitleText && (
          <StyledTitle fontSizeOverride={welcomeMessageFontSizeOverride}>
            <FoilAnimationWrapper text={title} />
          </StyledTitle>
        )}
      </TitleContainer>
      <OverlayAnimation
        hasBackgroundImage
        editWebsiteWedding={editWebsiteWedding}
        forceShowOverlay={forceShowOverlay}
      />
      {forceShowOverlay && <TintedOverlay />}

      {onDownCaretClick && (
        <MobileScrollDownCaret
          width={28}
          color={heroTitleStyles.color}
          onClick={onDownCaretClick}
        />
      )}
      {renderAbsoluteAssets({ a: heroAbsoluteAssets, containerWidth, device })}
    </div>
  );
};

export default Hero;
