import {
  AnimationProps,
  PageAnimationTypes,
} from '@zola/zola-ui/src/animations/PageAnimations/types';

export const getAnimationPropsBannerHero = (
  pageAnimation?: PageAnimationTypes
): Partial<AnimationProps> => {
  if (pageAnimation === 'page-animation-rise') {
    return {
      speed: 1.2,
      translateYPercent: 5,
    };
  }
  if (pageAnimation === 'page-animation-pan') {
    return {
      speed: 1.1,
      translateXPercent: 5,
    };
  }
  if (pageAnimation === 'page-animation-wipe') {
    return {
      speed: 2,
    };
  }
  if (pageAnimation === 'page-animation-breathe') {
    return {
      speed: 1.5,
      startScaleAt: 0.9,
    };
  }

  return {};
};

export const getAnimationPropsStackedHero = (
  pageAnimation?: PageAnimationTypes,
  shouldDelay?: boolean
): Partial<AnimationProps> => {
  if (
    pageAnimation === 'page-animation-pan' ||
    pageAnimation === 'page-animation-wipe' ||
    pageAnimation === 'page-animation-breathe'
  ) {
    return {
      speed: 2,
      delay: shouldDelay ? 0.25 : 0,
    };
  }

  return {
    delay: shouldDelay ? 0.25 : 0,
  };
};

export const getAnimationPropsSlideshowHero = (
  pageAnimation?: PageAnimationTypes
): Partial<AnimationProps> => {
  if (pageAnimation === 'page-animation-rise') {
    return {
      speed: 1.2,
      translateYPercent: 10,
    };
  }

  return {
    speed: 1.2,
  };
};

export const getAnimationPropsMarqueeHero = (
  pageAnimation?: PageAnimationTypes
): Partial<AnimationProps> => {
  if (pageAnimation === 'page-animation-pan') {
    return {
      speed: 1.4,
      translateXPercent: 10,
      easeFunction: 'cubic-bezier(0.2, 0, 0.1, 1)',
    };
  }
  if (pageAnimation === 'page-animation-wipe') {
    return {
      speed: 2,
    };
  }
  if (pageAnimation === 'page-animation-breathe') {
    return {
      speed: 1.6,
      startScaleAt: 0.95,
    };
  }

  return {};
};

export const getAnimationPropsFullWidthHero = (
  pageAnimation?: PageAnimationTypes
): Partial<AnimationProps> => {
  if (pageAnimation === 'page-animation-rise') {
    return {
      speed: 2,
      translateYPercent: 20,
    };
  }
  if (pageAnimation === 'page-animation-pan') {
    return {
      speed: 1,
      translateXPercent: 10,
    };
  }
  if (pageAnimation === 'page-animation-wipe') {
    return {
      speed: 3,
    };
  }
  if (pageAnimation === 'page-animation-breathe') {
    return {
      speed: 2,
      startScaleAt: 0.8,
    };
  }

  return {};
};

export const getAnimationPropsSideBySideHero = (
  pageAnimation?: PageAnimationTypes
): Partial<AnimationProps> => {
  if (pageAnimation === 'page-animation-rise') {
    return {
      speed: 1.5,
      translateYPercent: 10,
      easeFunction: 'cubic-bezier(0.3, 0, 0.1, 1)',
    };
  }
  if (pageAnimation === 'page-animation-pan') {
    return {
      speed: 1.5,
      translateXPercent: 10,
    };
  }
  if (pageAnimation === 'page-animation-wipe') {
    return {
      speed: 3,
    };
  }
  if (pageAnimation === 'page-animation-breathe') {
    return {
      speed: 2,
      startScaleAt: 0.8,
      easeFunction: 'cubic-bezier(0.2, 0, 0.1, 1)',
    };
  }

  return {};
};
